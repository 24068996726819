import React from 'react';
import Layout, { Content } from '../components/Layout';
import Button from '@material-ui/core/Button';
import { useAuth } from '../hooks/useAuth';
import HeroHeader from '../components/heroheader';

const AuthentificationRequired =  ({ pageContext, location }) => {
  const { login } = useAuth();
  return (
    <Layout title={'Authentification Required'}>
      <HeroHeader title="MyBUNAC" backgroundUrl="/images/mybunac.jpg" />
      <Content>
        <h1>Authentification required</h1>
        <p>You need to be authentificated to view this page</p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="contained" color="primary" onClick={login}>
            Please Login or Sign Up to continue
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

export default AuthentificationRequired;